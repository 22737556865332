'use client'

import Footer from '@/components/footer/siteFooter'
import { Header } from '@/components'
import { IntercomProvider } from 'react-use-intercom'

function AppLayout({ children }: { children: React.ReactNode }) {
  return (
    <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!} apiBase={'https://api-iam.intercom.io'} autoBoot>
      <Header />
      <div className='min-h-full bg-popover'>{children}</div>
      <Footer />
    </IntercomProvider>
  )
}

export default AppLayout
